import { ENV } from "./secret";
import GoogleButton from "react-google-button";
import { useState, useEffect } from "react";
//
import { googlePic } from "../../utils/img";
import "./GoogleAuth.css";

function GoogleAuth() {
    const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
    const REDIRECT_URI = 'auth/api/login/google/';
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : "https://django-craftshop-server.onrender.com";
    const scope = [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
    ].join(' ');

    useEffect(() => {
        console.log(`Redirect URL is ${BACKEND_URL}/${REDIRECT_URI}`);
    }, []);

    const googleLoginInit = () => {
        try {
            const params = {
                response_type: 'code',
                client_id: ENV.CLIENT_ID,
                redirect_uri: `${BACKEND_URL}/${REDIRECT_URI}`,
                prompt: 'select_account',
                access_type: 'offline',
                scope
            };

            const urlParams = new URLSearchParams(params).toString();
            window.location = `${GOOGLE_AUTH_URL}?${urlParams}`;


        } catch(e) {
            console.log("Error in the GoogleAuth function");
            console.log(e.message);
        }
    }

    return (
        <div 
        className="google-btn-overlay"
        onClick={googleLoginInit}
        >
       
            <img 
            id="google-btn"
            label="Sign in with Google"
            src={googlePic}
            />
        </div>
    )
};  

export default GoogleAuth