import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";
import 
{ 
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Box
} from "@mui/material";
import "./Profile.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// API
import 
{ 
    initiateLogout,
    modifyPassword,
    modifyUsername,
    modifyEmail,
    verifyLogin,
} 
from "../../api/user";
import { toggleLogout, loginUser } from "../../store/user";
import OrderItem from "./orders/OrderItem";

function Profile() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, isAuthenticated } = useSelector(state => state.user);
    const { orders } = user;

    // Set new Pass
    const [currentPass, setCurrentPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [verifyNewPass, setVerifyNewPass] = useState("");

    // Set new Username
    const [newUsername, setNewUsername] = useState("");
    const [verifyNewUsername, setVerifyNewUsername] = useState("");

    // Set new Email
    const [newEmail, setNewEmail] = useState("");
    const [verifyNewEmail, setVerifyNewEmail] = useState("");

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isAuthenticated === false) {
                navigate("/login");
            } 
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [isAuthenticated]);

    useEffect(() =>  {
        try {
            document.title = `${user.username} | Django Delight`;
        } catch(e) {
            return
        }
    }, [user]);

    // useEffect(() => {
    //     if (orders.length > 0) {
    //         console.log(`Orders in the profile component are: ${orders[0]["name"]}`);
    //     }
    // }, [orders])

    // Handle logout
    const handleLogout = async() => {
        try {   
            const result = await initiateLogout();
            if(result === "User is logged out") {
                // console.log("Logout was successful");
                return dispatch(toggleLogout());   
            };
            console.log("Logout was not successful !");
            return

        } catch(e) {
            console.log("Error in the handleLogout function");
            console.log(e);
        }
    };

    // Handle password change
    const handleChangePass = async(e) => {
        try {
            e.preventDefault();
            if (newPass !== verifyNewPass) {
                return alert("New password did not match");
            }
            if (currentPass === newPass) {
                return alert("Your current and new password dont differ!");
            }
            const result = await modifyPassword({
                "oldpass": currentPass,
                "newpass": newPass
            });
            if (result === "Password changed successfully for logged in user!") {
                alert("Password changed, login with new password!");
                await initiateLogout();
                return dispatch(toggleLogout());
            }
            return alert("Your old password is incorrect");
        } catch {   
            console.log("Error in the handleChangePass function");
            console.log(e);
        }
    };

    // Handle email change
    const handleChangeEmail = async(e) => {
        try {  
            e.preventDefault();
            if (newEmail !== verifyNewEmail) {
                return alert("Passwords do not match");
            }
            if (verifyNewEmail === user.email) {
                return alert("New and old emails are the same!");
            }
            const result = await modifyEmail({
                "newemail": verifyNewEmail
            });
            if (result === "Email has been changed successfully") {
                alert("Email has been changed successfully");
                const returned_user = verifyLogin();
                dispatch(loginUser(returned_user));
                return window.location.reload();
            }
            if (result === "Email in use!") {
                return alert("Email in use!")
            }
            return alert("Error in changing the email!");

        } catch(e) {
            console.log("Error in the handleChangeEmail function");
            console.log(e);
        }
    }

    // Handle username change
    const handleChangeUsername = async(e) => {
        try {
            e.preventDefault();
            if (newUsername !== verifyNewUsername) {
                return alert("Double check your spelling");
            }
            if (newUsername === user.username) {
                return alert("New and current username are the same!");
            };
            const result = await modifyUsername({
                "newusername": verifyNewUsername
            });
            if (result === "Username modified successfully") {
                alert("Username modified successfully! Log back in again");
                await initiateLogout();
                return dispatch(toggleLogout());
            }
            return alert("Username already taken! Choose another");
        } catch(e) {
            console.log("Error in the handleChangeUsername function");
            console.log(e);
        }
    }

    return (
        <>
        {user.username && (
            <div className="profile-overlay">
                <section className="profile-name-holder">
                    <AccountCircleIcon 
                    id="account-icon"
                    />
                    <Typography 
                    variant="h3"
                    id="username"
                    >
                        {user.username}
                    </Typography>
                    <Typography
                    variant="h4"
                    id="email"
                    >
                        {user.email}
                    </Typography>
                    <Typography
                    variant="h5"
                    id="credits"
                    >
                        Available credits: {user.credits}
                    </Typography>
                </section>
                <section className="profile-inner-container">
                    <Accordion 
                    
                    id="order-accordion"
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "black"}}/>}
                        >
                            <Typography
                            className="order-title"
                            >
                                Orders
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {orders.length > 0 ? (
                                <>
                                    {orders.map((item, idx) => (
                                        <OrderItem 
                                            name={item["name"]}
                                            id={item["id"]}
                                            price={item["price"]}
                                            qty={item["qty"]}
                                        />
                                    ))}
                                </>
                            ) : (
                                
                                <Typography
                                variant="h5"
                                className="order-accordion-text"
                                >
                                    There are no orders currently
                                </Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </section>
                <section className="settings-inner-container">
                    <Accordion 
                    
                    id="privacy-accordion"
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "black"}}/>}
                        >
                            <Typography
                            className="order-title"
                            >
                                Privacy
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                        id="privacy-settings-details"
                        >
                            <Box
                            id="privacy-settings"
                            style={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                            >
                                <Typography
                                className="privacy-set-title"
                                >
                                    Change Password
                                </Typography>
                                <Box
                                id="privacy-box"
                                >
                                    <form
                                    className="privacy-form"
                                    onSubmit={handleChangePass}
                                    >
                                        <div className="input-container">
                                            <Box
                                            className="box-holder"
                                            >
                                                <label>Current Password</label>
                                                <input 
                                                className="privacy-input"
                                                placeholder="Current Password"
                                                type="password"
                                                required
                                                onChange={(e) => setCurrentPass(e.target.value)}
                                                value={currentPass}
                                                />
                                            </Box>
                                            <Box
                                            className="box-holder"
                                            >
                                                <label>New Password</label>
                                                <input 
                                                className="privacy-input"
                                                placeholder="New Password" 
                                                type="password"
                                                required
                                                onChange={(e) => setNewPass(e.target.value)}
                                                value={newPass}
                                                />
                                            </Box>
                                            <Box
                                            className="box-holder"
                                            >
                                                <label>Repeat New Password</label>
                                                <input 
                                                className="privacy-input" 
                                                placeholder="New Password"
                                                type="password"
                                                required
                                                onChange={(e) => setVerifyNewPass(e.target.value)}
                                                value={verifyNewPass}
                                                />
                                            </Box>
                                        </div>
                                        
                                        <Button
                                        variant="contained"
                                        id="settings-submit-btn"
                                        type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </form>
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </section>
                <section className="settings-inner-container">
                    <Accordion
                    id="general-settings-accordion"
                    >
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{color: "black"}}/>}
                        >
                            <Typography
                            className="order-title"
                            >
                                General
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                        id="general-settings-details"
                        >
                            <Box
                            id="general-settings"
                            >
                                <Typography className="general-set-title">
                                    Change Username
                                </Typography>
                                <Box
                                id="general-set-box"
                                >
                                    <form
                                    className="settings-form"
                                    onSubmit={handleChangeUsername}
                                    >
                                        <div className="inner-settings-container">
                                            <Box
                                            className="box-holder-general"
                                            >
                                                <label>New Username</label>
                                                <input
                                                className="general-input" 
                                                placeholder="Choose New Username"
                                                required
                                                onChange={(e) => setNewUsername(e.target.value)}
                                                value={newUsername}
                                                />
                                            </Box>
                                            <Box
                                            className="box-holder-general"
                                            >
                                                <label>Veify New Username</label>
                                                <input
                                                className="general-input" 
                                                placeholder="Verify New Username"
                                                required 
                                                onChange={(e) => setVerifyNewUsername(e.target.value)}
                                                value={verifyNewUsername}
                                                />
                                            </Box>
                                            
                                        </div>
                                        <Button
                                        variant="contained"
                                        id="settings-submit-btn"
                                        type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </form>
                                </Box>

                            </Box>
                            <Box
                            id="general-settings"
                            >
                                <Typography className="general-set-title">
                                    Change Email Address
                                </Typography>
                                <Box
                                id="general-set-box"
                                >
                                    <form
                                    className="settings-form"
                                    onSubmit={handleChangeEmail}
                                    >
                                        <div className="inner-settings-container">
                                            <Box
                                            className="box-holder-general"
                                            >
                                                <label>New Email</label>
                                                <input
                                                className="general-input" 
                                                placeholder="Choose New Eamil Address"
                                                onChange={(e) => setNewEmail(e.target.value)}
                                                value={newEmail}
                                                required
                                                />
                                            </Box>
                                            <Box
                                            className="box-holder-general"
                                            >
                                                <label>Verify New Email</label>
                                                <input
                                                className="general-input" 
                                                placeholder="Verify New Email"
                                                onChange={(e) => setVerifyNewEmail(e.target.value)}
                                                value={verifyNewEmail}
                                                required 
                                                />
                                            </Box>
                                            
                                        </div>
                                        <Button
                                        variant="contained"
                                        id="settings-submit-btn"
                                        type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </form>
                                </Box>

                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </section>
                <Button
                id="logout-button"
                onClick={() => handleLogout()}
                >
                    Logout
                </Button>
            </div>

        )}
        </>
    )
};

export default Profile;