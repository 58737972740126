import 
{
    Button,
    Typography,
    TextField, 
    MenuItem,
    Snackbar,
    Slide,
    SnackbarContent,
    CircularProgress
} from "@mui/material";
import { useState, useEffect } from "react";
import "./Recipes.css";
import RecipesAPI from "../../api/recipe";
import RecipeCard from "./recipe/Recipe";
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
///
const RecipesApi = new RecipesAPI();
const fillArr = new Array(20).fill(0);
const qtyArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
//
import { fetchRecipes } from "../../store/recipe";
import LoadingScreen from "../shared/loadingScreen/loadingScreen";

function Recipes() {
    const { ingredients } = useSelector(state => state.ingredient);
    const { isAuthenticated } = useSelector(state => state.user);
    const { recipes } = useSelector(state => state.recipe);

    const [open, setOpen] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSnackOpen = () => setSnackOpen(true);
    const handleSnackClose = () => setSnackOpen(false);

    const handleCartOpen = () => setCartOpen(true);
    const handleCartClose = () => setCartOpen(false);

    useEffect(() => {
        if (!isAuthenticated && open) {
            navigate("/login");
        }
    }, [open]);

    useEffect(() => {
        document.title = `Recipes | Django Delight`;
    }, []);

    // New Recipe form input
    const [recipeName, setRecipeName] = useState("");
    const [recipeDescription, setRecipeDescription] = useState("");
    const [recipeImg, setRecipeImg] = useState(null);
    const [recipeIngr, setRecipeIngr] = useState([
        { "ingredient": "", "qty": 1 },
        { "ingredient": "", "qty": 1 },
        { "ingredient": "", "qty": 1 }
    ]);

    const handleAddRecipe = async(e) => {
        try {
            e.preventDefault();
            const tempObj = {};
            const MAX_FILE_SIZE = 0.5 * 1024 * 1024;
            // Verify file size
            if (recipeImg.size > MAX_FILE_SIZE) {
                //setRecipeImg(null);
                return alert("Size of image must me smaller than 500kb!");
            }
            // Check if the ingredients array contains empty objects
            const isEmpty = recipeIngr.every(e => e["ingredient"] !== "");
            // console.log(`isEmpty is ${isEmpty}`);
            if (!isEmpty) {
                return alert("One if in the ingredient brackets is empty");
            }
            // Counting occurances
            recipeIngr.forEach(ingredient => {
                let currentIngr = ingredient["ingredient"];
                tempObj[currentIngr] = tempObj[currentIngr] ? tempObj[currentIngr] + 1 : 1;
            })

            const isUnique = Object.values(tempObj).every(e => e === 1);
            // console.log(`tempObj is ${Object.values(tempObj)}`);
            if (isUnique === false) {
                return alert("You included duplicate ingredients!");
            }

            const formData = new FormData();
            formData.append("name", recipeName);
            formData.append("description", recipeDescription);
            formData.append("ingredients", JSON.stringify(recipeIngr));
            formData.append("img", recipeImg);

            const result = await RecipesApi.addNewRecipe(formData);
            if (result === "Insert was successful!") {
                const result = await RecipesApi.getRecipes();
                for (let recipe of result) {
                    if (recipe["image"]) {
                        recipe["image"] = `data:image/webp;base64,${recipe["image"]}`;
                    } else {
                        continue
                    }
                }   
                if (result.length > 0) {
                    dispatch(fetchRecipes(result));
                    handleClose();
                    handleSnackOpen()
                } else {
                    return dispatch(toggleHasError());
                }

            } else {

                return alert("Insert was not successful");
            }
            
        } catch(e) {
            console.log("Error in the handleAddRecipe function");
            console.log(e.message);
        }
    };

    const handleAddIngr = () => {
        const newArr = [...recipeIngr, { "ingredient": "", "qty": 0 }];
        setRecipeIngr(newArr);
    };

    const handleRemoveIngr = (index) => {
        const newArr = [...recipeIngr];
        newArr.splice(index, 1);
        setRecipeIngr(newArr);
    };

    const handleSetIngr = (index, event) => {
        setRecipeIngr(prev => {
            const newArr = [...prev];
            newArr[index]["ingredient"] = event.target.value;
            return newArr
        });
    };

    const handleSetQty = (index, event) => {
        setRecipeIngr(prev => {
            const newArr = [...prev];
            newArr[index]["qty"] = event.target.value;
            return newArr
        })
    };

    const handleSetImg = (e) => {
        // const MAX_FILE_SIZE = 0.5 * 1024 * 1024;
        const file = e.target.files[0];
       
        setRecipeImg(file);
    }

    return (
        <div className="recipes-overlay">
            <Snackbar
            open={snackOpen}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            autoHideDuration={3000}
            message="Recipe was added"
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            id="snackbar"
            >
                <SnackbarContent 
                message="Recipe was added" 
                id="snackbar-content"
                />
            </Snackbar>
            <Snackbar
            style={{
                // backgroundColor: "rgb(204, 255, 153)",
            }}
            open={cartOpen}
            onClose={handleCartClose}
            message="Item placed in Cart!"
            key="success-notification"
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            TransitionComponent={Slide}
            autoHideDuration={3000}
            id="snackbar"
            >
                <SnackbarContent 
                message="Recipe was added to cart" 
                id="snackbar-content"
                />
            </Snackbar>
            {/* mobile snackbar */}
            <Snackbar
            open={snackOpen}
            onClose={handleSnackClose}
            TransitionComponent={Slide}
            autoHideDuration={3000}
            message="Recipe was added"
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            id="mobile-snackbar"
            >
                <SnackbarContent 
                message="Recipe was added" 
                id="snackbar-content"
                />
            </Snackbar>
            <Snackbar
            style={{
                // backgroundColor: "rgb(204, 255, 153)",
            }}
            open={cartOpen}
            onClose={handleCartClose}
            message="Item placed in Cart!"
            key="success-notification"
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            TransitionComponent={Slide}
            autoHideDuration={3000}
            id="mobile-snackbar"
            >
                <SnackbarContent 
                message="Recipe was added to cart" 
                id="snackbar-content"
                />
            </Snackbar>
            <section className="add-recipe-container">
                <Button 
                variant="contained" 
                id="add-btn"
                onClick={handleOpen}
                >
                    Add recipe<AddIcon id="add-icon"/>
                </Button>
            </section>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
                <div
                className="modal-container"
                >
                    <form 
                    onSubmit={handleAddRecipe}
                    className="add-recipe-form"
                    >
                        <section className="recipe-input-general-container">
                            <div className="recipe-input-general-holder">
                                <label className="name-label">Recipe name</label>
                                <input
                                required
                                id="general-input"
                                type="text" 
                                name="recipe_name"
                                placeholder="Recipe name"
                                value={recipeName}
                                onChange={(e) => setRecipeName(e.target.value)}
                                />
                            </div>
                            <div className="recipe-input-general-holder">
                                <label className="description-label">Recipe description</label>
                                <textarea 
                                id="description-input"
                                required
                                type="text"
                                name="recipe_description"
                                placeholder="Recipe description"
                                value={recipeDescription}
                                onChange={(e) => setRecipeDescription(e.target.value)}
                                />
                                
                            </div>
                            <div className="recipe-input-general-holder">
                                <label className="image-label">
                                    Upload image, only of format .webp
                                </label>

                                    <input 
                                    id="upload-input"
                                    type="file"
                                    accept=".webp"
                                    onChange={handleSetImg}
                                    required 
                                    />
                            </div>
                        </section>
                        <seciton className="add-ingredients-container">
                            <div className="add-ingredients-inner-container">
                                <label className="ingredients-label">Add ingredients</label>
                                {recipeIngr.map((e, idx) => (
                                    <div className="ingredient-row">
                                        <div className="column">
                                            <label>Name</label>
                                            <TextField
                                            select
                                            id="ingredient-select"
                                            value={recipeIngr[idx]["ingredient"]}
                                            onChange={(e) => handleSetIngr(idx, e)}
                                            >
                                                {ingredients.map((ingr, _) => (
                                                    <MenuItem
                                                    id="ingredient-menuitem"
                                                    key={ingr.id}
                                                    value={ingr.name}
                                                    
                                                    >
                                                        {ingr.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className="column">
                                            <label>Quantity</label>
                                            <TextField
                                            select
                                            id="quantity-select"
                                            value={recipeIngr[idx]["qty"]}
                                            onChange={(e) => handleSetQty(idx, e)}
                                            >
                                                {qtyArr.map((d, _) => (
                                                    <MenuItem
                                                    id="qty-menuitem"
                                                    key={d}
                                                    value={d}
                                                    >
                                                        {d}
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                        </div>
                                        <div className="options-panel">

                                            <div className="options-column">
                                                
                                                <RemoveCircleOutlineIcon 
                                                id="btn"
                                                onClick={(idx) => handleRemoveIngr(idx)}
                                                />
                                                <AddCircleIcon 
                                                id="btn"
                                                onClick={() => handleAddIngr()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </seciton>
                        <Button
                        type="submit"
                        id="submit-btn"
                        >
                            Submit
                        </Button>                    
                    </form>

                </div>
            </Modal>
            {recipes.length > 0 ? (
                <>
                    <section className="recipes-inner-container">
                        {recipes.map((e, idx) => <RecipeCard 
                                                id={e["id"]}
                                                name={e["name"]}
                                                ingredients={e["ingredients"]}
                                                description={e["description"]}
                                                img={e["image"]}
                                                price={e["price"]}
                                                handleCartOpen={handleCartOpen}
                                                /> )}
                    </section>
                </>
            ) : (
                <div className="loadscreen-container">
                    <LoadingScreen />
                </div>
            )}
        </div>
    )
};

export default Recipes;