import { Typography, Button } from "@mui/material";
import { Popup } from "semantic-ui-react";
import "./Ingredient.css";
import { infoImg } from "../../../utils/img";

function Ingredient({ name, qty, description }) {
    const trigger = (
        <div className="trigger-container">
            <img 
            src={infoImg}
            className="trigger-img"
            />
        </div>
    );

    const popupStyle = {
        backgroundColor: " rgb(204, 255, 153)",
        opacity: 0.9,
        borderRadius: "10px",
        width: "10vw", 
        height: "auto",
        padding: "1vw",
        textAlign: "center",
        width: "10vw"
    }

    const descriptionAppear = (
        <div
        className="popup-container"
        >
            {description}
        </div>
    )

    return (
        <div className="ingredient-card-container">
            <section className="ingredient-details-container first">
                <Typography id="ingredient-name">
                    {name[0].toUpperCase() + name.slice(1)}
                </Typography>
            </section>
            <section className="ingredient-details-container second">
                <span>Stock:</span> 
                <Typography 
                id="igredient-quantity"
                style={{
                    color: `${qty < 20 ? "red" : "green"}`,
                }}
                >
                    {qty}
                </Typography>
            </section>
            <section className="ingredient-details-container third">
                <Popup
                id="popup-box" 
                basic
                trigger={trigger}
                style={popupStyle}
                content={descriptionAppear}
                />
            </section>
        </div>
    )
};

export default Ingredient;