
import "./Footer.css";
import { Typography } from "@mui/material"
import { NavLink } from "react-router-dom";
import { portfolioPic, cv } from "../../utils/img";
import { Outlet } from "react-router-dom";

function Footer() {
    return (
        <>
            <Outlet />  
            <div className="footer-container">
                <div className="text-box">
                    <a 
                    id="reference-nav"
                    href="https://apatsche-portfolio.onrender.com"
                    target="_blank"
                    >
                        <span>
                            All credits goes to:
                        </span>
                        <img 
                        src={portfolioPic}
                        />
                    </a>
                </div>
            </div>  
        </>
    )
};

export default Footer