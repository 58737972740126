import API from "./client";

class RecipesAPI {

    async getRecipes() {
        try {
            const result = await API.get("recipes/");
            return result.data                
        } catch(e) {
            console.log("Error in the getRecipes method API");
            console.log(e.message);
        }   
    };

    async getRecipe(name) {
        try {
            const result = await API.get(`recipes/specific/${name}/`);
            return result.data;
        } catch(e) {
            console.log("Error in the getRecipe methid API");
            console.log(e.message);
        }
    };

    async addNewRecipe(data) {
        try {
            const result = await API.post("recipes/create/", data);
            return result.data
        } catch(e) {
            console.log("Error in the addNewRecipe method API");
            console.log(e.message);
        }
    };
    // ADD COMMENT
    async addComment(data) {
        try {   
            const result = await API.post(`recipes/addComment/${data["name"]}/`, data);
            return result.data
        } catch(e) {
            console.log(`Error in the addComment api method: ${e.message}`);
        }
    };
    // DELETE COMMENT
    async deleteComment(id) {
        try {
            const result = await API.delete(`recipes/deleteComment/${id}/`);
            return result.data
        } catch(e) {
            console.log(`Error in the deleteComment api method: ${e.message}`);
        }
    }
    
};

export default RecipesAPI;