import "./RecipeDetails.css";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button, MenuItem, TextField, Snackbar, Slide, SnackbarContent } from "@mui/material";
import { NavLink } from "react-router-dom";
import RecipesAPI from "../../api/recipe";
import CartAPI from "../../api/cart";
import IngredientsAPI from "../../api/ingredient";
import { verifyLogin } from "../../api/user";
import { fetchRecipe, toggleHasError } from "../../store/recipe";
import { loginUser } from "../../store/user";
import RecipeComment from "./recipeComment/recipeComment";
import { Modal } from "@mui/material";
//
import { com, com_com } from "../../utils/img";
import LoadingScreen from "../shared/loadingScreen/loadingScreen";

export default function RecipeDetails() {

    const numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let { name } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const IngredientsApi = new IngredientsAPI();
    const RecipesApi = new RecipesAPI();
    const CartApi = new CartAPI();

    const { recipe } = useSelector(state => state.recipe);
    const ingredientsArr = useSelector(state => state.ingredient.ingredients);
    const { isAuthenticated, user } = useSelector(state => state.user);

    const [comment, setComment] = useState("");

    const [qty, setQty] = useState(1);
    //
    const [isModalOpen, setIsModelOpen] = useState(false);

    const handleModalClose = () => setIsModelOpen(false);
    const handleModalOpen = () => setIsModelOpen(true);

    useEffect(() => {
        const getRecipeDetails = async () => {
            try {
                const timeoutId = setTimeout(() => {
                    setIsLoading(false);
                }, 1000)
                const result = await RecipesApi.getRecipe(name);
                if (result.name) {
                    result["image"] = `data:image/webp;base64,${result["image"]}`;
                    dispatch(fetchRecipe(result));
                    return () => clearTimeout(timeoutId);
                }
                dispatch(toggleHasError());
                return () => clearTimeout(timeoutId);
            } catch (e) {
                console.log("Error in the getRecipeDetails within RecipeDetails.js");
                console.log(e.message);
            }
        };
        getRecipeDetails();
    }, [name]);

    useEffect(() => {
        document.title = `${name} | Django Delight`;
    }, []);

    useEffect(() => {
        console.log(`isLoading in the RecipeDetails component has changed to ${isLoading}`);
    }, [isLoading])

    // useEffect(() => {
    //     console.log(`User id in the  RecipeDetails component is: ${user["id"]}`);
    // }, [user]);

    const handlePlaceInCart = async (e) => {
        try {
            e.preventDefault();
            if (!isAuthenticated) {
                return navigate("/login");
            }

            const result = await CartApi.addToCart({ "name": recipe.name, "qty": qty });

            if (result === "Cart Updated!" || result === "Cart Record Created!") {

                // dispatch(addIngredients(ingr_result));
                const user_obj = await verifyLogin();
                if (user_obj["username"]) {
                    for (let cartItem of user_obj["cart"]) {
                        cartItem["image"] = `data:image/webp;base64,${cartItem["image"]}`;
                    }
                    dispatch(loginUser(user_obj));
                    return handleOpen();
                }
                // window.location.reload();
                return alert("Cart Updated!");

            }

            return alert("Cart update/creation was not successful!");
        } catch (e) {
            console.log(e.message);
            console.log("Error in the handlePlaceInCart function");
        }
    };

    const handleAddComment = async(e) => {
        try {   
            e.preventDefault();
            if (!isAuthenticated) {
                return navigate("/login");
            }
            const data = {
                "user_id": user["id"],
                "name": name,
                "content": comment
            };
            const result = await RecipesApi.addComment(data);
            if (result.name) {
                console.log(`Retrieved recipe from db is ${result.name}`);
                result["image"] = result["image"] ? `data:image/webp;base64,${result["image"]}` : null;
                dispatch(fetchRecipe(result));
                setComment("");
                return handleModalClose()
            }
            return console.log(`Could not retrieve comments from the db: ${result}`);

        } catch(e) {
            console.log(`Error i nthe handleAddComment API call ${e.message}`);
        }
    }

    if (isLoading) {
        return (
            <LoadingScreen />
        )
    }

    return (
        <div className="recipedetails-overlay">
            <Modal
            open={isModalOpen}
            onClose={handleModalClose}
            >
                <div className="comment-modal">
                    <h3 className="title">Write commment</h3>
                    <textarea
                    required
                    
                    id="general-input"
                    type="text" 
                    name="recipe_name"
                    placeholder="Recipe name"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    />
                    <div
                    className="button-holder"
                    >

                        <Button
                        id="submit-btn"
                        disabled={comment.length === 0}
                        variant="contained"
                        onClick={handleAddComment}
                        >
                                Submit
                        </Button>
                    </div>
                </div>
            </Modal>
            <Snackbar
                open={open}
                onClose={handleClose}
                TransitionComponent={Slide}
                autoHideDuration={3000}

                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                id="snackbar"
            >
                <SnackbarContent
                    message="Recipe was added"
                    id="snackbar-content"

                >

                </SnackbarContent>
            </Snackbar>
            <Snackbar
                open={open}
                onClose={handleClose}
                TransitionComponent={Slide}
                autoHideDuration={3000}

                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                id="mobile-snackbar"
            >
                <SnackbarContent
                    message="Recipe was added"
                    id="snackbar-content"

                >

                </SnackbarContent>
            </Snackbar>
            <section
                className="container-section"
            >
                <div className="container-inner first">
                    <div
                        className="img-container"
                        style={{
                            backgroundImage: `url(${recipe.image})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                        }}
                    >

                    </div>
                    <div className="details-container">
                        <Typography
                            variant="h3"
                            id="recipe-title"
                        >
                            {recipe.name}
                        </Typography>

                    </div>

                </div>
                <div className="container-inner second">
                    <section className="box">
                        <Typography className="box-text">
                            Published by
                        </Typography>
                        <div className="navlink-holder">
                            <NavLink
                                id="user-navlink"
                                to="#">
                                {recipe.publisher}
                            </NavLink>
                        </div>
                    </section>
                    <section className="box">
                        <Typography className="box-text">
                            Ingredients
                        </Typography>
                        <div className="ingredients-holder">
                            <ul>
                                {recipe.ingredients.length > 0 && recipe.ingredients.map((e, idx) => (
                                    <li
                                    key={idx}
                                    >
                                        {e.name}<span>{e.qty_required}</span>
                                    </li>
                                ))}

                            </ul>
                        </div>
                    </section>
                    <section className="box">
                        <Typography className="box-text">
                            Description
                        </Typography>
                        <div className="description-holder">
                            <p>{recipe.description}</p>
                        </div>
                    </section>
                    <section className="order-box">
                        <TextField
                            required
                            select
                            id="quantity-select"
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                        >
                            {numArr.map((num, idx) => (
                                <MenuItem
                                    key={idx}
                                    value={num}
                                >
                                    {num}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button
                            variant="contained"
                            id="order-btn"
                            onClick={handlePlaceInCart}
                        >
                            Order
                        </Button>
                    </section>
                </div>
            </section>
            <div className="comment-container">
                <div className="heading">
                    <h2>Comments</h2>
                    <Button
                    id="comment-btn"
                    variant="contained"
                    onClick={handleModalOpen}
                    
                    >
                        <img 
                        src={com}
                        id="comment-icon"
                        />
                    </Button>
                </div>
                {recipe["comments"].length > 0 ? 
                    (   
                        recipe["comments"].map((obj, index) => <RecipeComment key={index} data={obj}/>)
                    ) : (
                        <>
                            <h2></h2>
                        </>
                    )}
            </div>
        </div>
    );
}
