import { useState, useEffect } from "react";
import "./Login.css";
import { Button } from "@mui/material";
import { NavLink, redirect, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, toggleIncorrectCreds } from "../../store/user";
import GoogleAuth from "../google_auth/GoogleAuth";
// API
import { fetchUser } from "../../api/user";
//

function Login() {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { isAuthenticated, isLoading, invalidCredsUsed, user } = useSelector(state => state.user);

    useEffect(() => {
        if (isAuthenticated === true) {
            // console.log("Redirect should run");
            navigate("/");
        };
    }, [isAuthenticated]);

    useEffect(() => {
        document.title = `Login | Django Delight`;
    }, []);

    const submitForm = async(e) => {

        try {
            e.preventDefault()
            if (username.trim("").length < 1 || password.trim("").length < 1) {
                return alert("Fill out every field");
            }
            const data = { username, password };
            const result = await fetchUser(data);
            if (username !== result.username) {
                dispatch(toggleIncorrectCreds());
                return 
            };
            if (username === result.username) {
                for (let cartItem of result["cart"]) {
                    cartItem["image"] = `data:image/webp;base64,${cartItem["image"]}`;
                }
                return dispatch(loginUser(result));
                
            }

        } catch(e) {
            console.log("Error in the submitForm function");
            console.log(e);
        }
    };

    return (
        <div className="login-overlay">
            <section className="login-inner-container">
                <div className="content-holder one">
                    <div className="content-inner-holder">

                    </div>
                    <div className="title-holder">
                        <h4 className="welcome-msg">
                            Welcome back to django's craftery!
                        </h4>
                    </div>
                </div>
                <div className="content-holder two">
                    <div className="login-cont-holder first">
                        <h1 className="login-title">Login</h1>
                    </div>
                    <div className="login-cont-holder second">
                        <form 
                        className="login-form"
                        onSubmit={submitForm}
                        >
                            <div className="field">
                                <label >Username</label>
                                <input 
                                type="text"
                                name="username" 
                                placeholder="username"
                                value={username} 
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                />
                            </div>
                            <div className="field">
                                <label >Password</label>
                                <input 
                                type="password" 
                                name="password" 
                                placeholder="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                />
                            </div>
                            <Button
                            id="submit-button" 
                            type="submit" 
                            variant="contained"
                            >
                                Submit
                            </Button>
                            {invalidCredsUsed && <p style={{color: "red", marginTop: "0.3vw"}}>Incorrect credentials!</p>}
                        </form>
                    </div>
                    <div className="login-cont-holder three">
                        <p>Don't have an accout yet?<NavLink to="/register"> Sign Up</NavLink></p>
                        <div>

                            <GoogleAuth />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Login;