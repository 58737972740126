import "./Recipe.css";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import 
{ 
    Typography, 
    Button, 
    Divider,
    TextField, 
    MenuItem, 
    Snackbar, 
    Slide,
    CircularProgress
} from "@mui/material";
//Api
import CartAPI from "../../../api/cart";
import IngredientsAPI from "../../../api/ingredient";
import { verifyLogin } from "../../../api/user";
//
import { loginUser } from "../../../store/user";
import { addIngredients } from "../../../store/ingredient";
import { useNavigate } from "react-router-dom";

function RecipeCard({ name, description, img, ingredients, price, id, handleCartOpen }) {

    const CartApi = new CartAPI();
    const IngredientsApi = new IngredientsAPI();

    const [qty, setQty] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const ingredientsArr = useSelector(state => state.ingredient.ingredients);
    const { isAuthenticated } = useSelector(state => state.user);

    const handlePlaceInCart = async(e) => {
        try {
            e.preventDefault();
            // return console.log("Enough ingredients available product can be placed in cart");
            if (!isAuthenticated) {
                return navigate("/login");
            }

            const result = await CartApi.addToCart({
                "name": name,
                "qty": qty
            });

            if (result === "Cart Updated!" || result === "Cart Record Created!") {
                // const ingr_result = await IngredientsApi.getAll()
                
                // dispatch(addIngredients(ingr_result));
                const user_obj = await verifyLogin();
                if (user_obj["username"]) {
                    for (let cartItem of user_obj["cart"]) {
                        cartItem["image"] = `data:image/webp;base64,${cartItem["image"]}`;
                    }
                    dispatch(loginUser(user_obj));
                    return handleCartOpen();
                }
                // window.location.reload();
                
            }
           
            return alert("Cart update/creation was not successful!");

        } catch(e) {
            console.log("Error in the handlePlaceInCart function");
            console.log(e.message);
        }
    }

    return (
        <div className="recipe-card-container">
            
            <NavLink 
            to={`${name}`}
            id="recipe-details-navlink"
            >
                <section className="recipe-section first">
                    <div 
                    className="img-holder"
                    style={{
                        backgroundImage: `url(${img})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        
                    }}
                    >
                    </div>
                    <Typography
                    variant="h5"
                    id="recipe-name"
                    >
                        {name}
                    </Typography>
                    <Typography
                    id="mobile-price-view"
                    >
                        For <span>{price}</span>
                    </Typography>
                    <Divider />
                </section>
                <section className="recipe-section second">
                    <div className="ingredient-holder">
                        {ingredients.length > 0 && ingredients.map((e, idx) => (
                            <div className="ingredient-content">
                                <Typography
                                key={idx}
                                id="name"
                                >
                                    #{e.name}
                                </Typography>
                            </div>
                        ))}
                    </div>
                    
                </section>
            </NavLink>
            <section className="recipe-section third">
                <div className="promo-holder first">
                    <span className="adv-text">Only for </span>
                    <Typography 
                    id="price"
                    >
                        {price}
                    </Typography>
                </div>
                <div className="promo-holder second">
                    <Typography
                    variant="h4"
                    id="quantity-text"
                    >
                        Select Quantity
                    </Typography>
                    
                    <TextField
                    required
                    select
                    id="quantity-select"
                    value={qty}
                    onChange={(e) => setQty(e.target.value)}
                    >
                        {nums.map((num, idx) => (
                            <MenuItem
                            key={idx}
                            value={num}
                            >
                                {num}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Button
                    variant="contained"
                    id="order-btn"
                    onClick={handlePlaceInCart}
                    >
                        Order
                    </Button>
                </div>
            </section>
        </div>
    )
};

export default RecipeCard;