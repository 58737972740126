import { useState, useEffect, useReducer, useCallback } from "react";
import { Search, Label } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./SearchBar.css";

const initialState = {
    loading: false,
    results: [],
    value: ''
};

const exampleReducer = (state, action) => {
    switch (action.type) {
        case "CLEAN_QUERY":
            return initialState
        case "START_SEARCH":
            return {...state, loading: true, value: action.query}
        case "FINISH_SEARCH":
            return {...state, loading: false, results: action.results}
        case "UPDATE_SELECTION":
            return {...state, value: action.selection}
    }
};
function SearchBar() {

    const { recipes } = useSelector(state => state.recipe);
    const [state, dispatch] = useReducer(exampleReducer, initialState);
    const [localRecipes, setLocalRecipes] = useState([]);

    useEffect(() => {
        try {
            // console.log(`The 0th elements id is ${recipes[0].name}`);
            setLocalRecipes(recipes);

        } catch(e) {
            return
        }
    }, [recipes]);

    const handleSearch = useCallback((e, data) => {
        try {
            dispatch({ type: "START_SEARCH", query: data.value });
            const timeoutId = setTimeout(() => {
                if (data.value.length === 0) {
                    dispatch({ type: "CLEAN_QUERY" });
                    return
                }

                const isMatch = localRecipes.filter(recipe => recipe.name.includes(data.value.toLowerCase()));
                dispatch({type: "FINISH_SEARCH", results: isMatch});
    
            }, 300); 
            return () => clearTimeout(timeoutId);
        } catch(e) {
            console.log("")
            console.log(e.message);
        }
    }, [localRecipes]);

    const resultRenderer = (result) =>  (<NavLink className="result-nav" style={{width: "100%", }} to={`/recipes/${result.name}`}>
                                            <img 
                                            id="search-img"
                                            src={result.image}
                                            />
                                            <Label className="item-label" content={result.name} style={{ width: "100%" }}/>
                                        </NavLink>)

    return (
        <div className="searchbar-container">
            <Search 
            id="searchbar"
            value={state.value}
            loading={state.loading}
            onResultSelect={(e, data) => {
                dispatch({type: "UPDATE_SELECTION", selection: data.result.name})
            }}
            onSearchChange={handleSearch}
            resultRenderer={resultRenderer}
            results={state.results}
            />
        </div>
    )
};

export default SearchBar
