import "./OrderItem.css";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
import { bag } from "../../../utils/img";

function OrderItem({ name, id, price, qty }) {
    return (
        <div className="order-item-container">
            <section className="order-box first">
                <img 
                id="cart-image"
                src={bag}
                />
            </section>
            <section className="order-box second">
                <NavLink 
                className="order-navlink"
                to={`/recipes/${id}`}
                >
                    <Typography
                    className="navlink-text"
                    >

                        {name}
                    </Typography>

                </NavLink>
                <div className="qty-holder">
                    <span>Quantity: </span>
                    <Typography className="qty-text">
                        {qty}
                    </Typography>
                </div>
            </section>
            <section className="order-box third">
                <span>For:</span>
                <Typography className="price">
                    {price}
                </Typography>
            </section>
        </div>
    )
};

export default OrderItem;