import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Cart.css";
import { Typography, Button, Snackbar, SnackbarContent, Slide } from "@mui/material";
//
import CartItem from "./cartItem/CartItem";
import { loginUser } from "../../store/user";
import { addIngredients } from "../../store/ingredient";
import { courier } from "../../utils/img";
//api
import CartAPI from "../../api/cart";
import IngredientsAPI from "../../api/ingredient";
import { verifyLogin } from "../../api/user";

function Cart() {
    const CartApi = new CartAPI();
    const IngredientsApi = new IngredientsAPI();

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const { ingredients } = useSelector(state => state.ingredient);
    const { cart, credits } = useSelector(store => store.user.user);

    useEffect(() => {
        document.title = `Checkout | Django Delight`;
    }, []);

    const calcTotal = () => {
        return Math.floor(cart.reduce((acc, curr) => acc + (curr.qty * curr.price), 0) * 10) / 10;
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCartCheckout = async(e) => {
        try {
            e.preventDefault();
            if (calcTotal() > credits) {
                return alert("Not enough credits, start begging to daddy for moneey!");
            }
            let arg = [];
            for (let recipe of cart) {
                let arg_temp = {
                    "id": recipe["id"],
                    "price": recipe["price"],
                    "qty": recipe["qty"],
                    "ingredients": recipe["ingredients"],
                }

                arg.push(arg_temp);
            }            

            const result = await CartApi.cartCheckout({recipes: arg});
            console.log(result)
            if (result.includes("records created")) {
                    const user_obj = await verifyLogin();
                    const ingredients = await IngredientsApi.getAll();
                    if (user_obj["username"]) {
                        dispatch(loginUser(user_obj));
                    };
                    if (result === "Ingredients could not be retrieved") {
                        return dispatch(toggleError());
                    };
                    dispatch(addIngredients(ingredients));
                    return handleOpen()
            };
            if (result.includes("Missing")) {
                return alert(result);
            }
            return alert("Order was not successful");
        } catch(e) {
            console.log(e.message);
            console.log("Error in the handleCartCheckout function");
        }
    };

    return (
        <div className="cart-overlay">
            <Snackbar
            open={open}
            onClose={handleClose}
            TransitionComponent={Slide}
            autoHideDuration={3000}
            anchorOrigin={{vertical: "bottom", horizontal: "left"}}
            id="snackbar"
            > 
                <SnackbarContent 
                message="Purchase Successful!" 
                id="snackbar-content"
                >

                </SnackbarContent>
            </Snackbar>
            <Snackbar
            open={open}
            onClose={handleClose}
            TransitionComponent={Slide}
            autoHideDuration={3000}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            id="snackbar-mobile"
            > 
                <SnackbarContent 
                message="Purchase Successful!" 
                id="snackbar-content"
                >

                </SnackbarContent>
            </Snackbar>
            <section className="cart-container">
                {cart.length > 0 ? (
                    <section
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                    >
                        {cart.map((e, idx) => (
                            <CartItem
                            id={e["id"]} 
                            name={e["name"]}
                            img={e["image"]}
                            quantity={e["qty"]}
                            price={e["price"]}
                            ingredients={e["ingredients"]}
                            />
                        ))}
                        <div
                        style={{
                            width: "100%",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            
                        }}
                        className="btn-holder"
                        >
                            <div
                            style={{
                                width: "100%",
                              
                                display: "flex",
                                alignItems: "flex-end",
                                flexDirection: "column",
                                marginTop: "1vw",
                            }}
                            >
                                
                            
                                    <span
                                    style={{
                                        fontSize: "1vw",
                                    }}
                                    >Total:</span>
                                    <Typography
                                    style={{
                                        fontFamily: "Madimi",
                                        
                                    }}
                                    variant="h3"
                                    >
                                        {`${calcTotal()}`}
                                    </Typography>
                                
                            </div>
                            <div>

                                <Button
                                variant="contained"
                                style={{
                                    backgroundColor: "rgb(204, 255, 153)",
                                }}
                                onClick={handleCartCheckout}
                                >
                                    <Typography
                                    variant="h5"
                                    style={{
                                        color: "black"
                                    }}
                                    >
                                        Place order
                                    </Typography>
                                    <img 
                                    id="courier-img"
                                    src={courier}
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        marginLeft: "5px",
                                    }}
                                    />
                                </Button>
                            </div>
                        </div>
                    </section>    
                ) : (
                        <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: "20%",
                            paddingBottom: "20%",
                           
                        }}
                        >
                            <Typography
                            variant="h3"
                            style={{
                                textAlign: "center",
                                padding: "1vw",
                                borderRadius: "10px",
                                backgroundColor: "white",
                                fontFamily: "Courgette"
                            }}
                            >
                                Shopping Cart is empty
                            </Typography>
                        </div>
                    )}
            </section>
        </div>
    )
};

export default Cart

