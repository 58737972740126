import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: { cart: [], orders: [] },
        isAuthenticated: false,
        isLoading: false,
        invalidCredsUsed: false
    },
    reducers: {
        loginUser: (state, action) => {
            Object.assign(state, { user: action.payload });
            state.isAuthenticated = true;
            state.invalidCredsUsed = false;
            state.isLoading = false;
        },
        toggleIsLoading: (state) => {
            state.isAuthenticated = false;
            state.isLoading = true;
        },
        toggleIncorrectCreds: (state) => {
            state.isAuthenticated = false;
            state.invalidCredsUsed = true
        },
        toggleLogout: (state) => {
            state.isAuthenticated = false;
            state.isLoading = false;
        },
        removeCartItemFromStore: (state, action) => {
            state.user.cart.filter(e => e.id !== action.payload);
        }
    }
});

export const { 
                loginUser,
                toggleIncorrectCreds,
                toggleIsLoading, 
                toggleLogout,
                removeCartItemFromStore
             } = userSlice.actions;
export default userSlice.reducer;
