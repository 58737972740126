import API from "./client";

class IngredientsAPI {

    async getAll() {
        try {
            const result = await API.get("ingredients/");
            return result.data;
        } catch(e) {
            console.log("Error in the getAll api method");
            console.log(e.message);
        }
    }
}

export default IngredientsAPI;