import { createSlice } from "@reduxjs/toolkit";

const ingredientReducer = createSlice({
    name: "ingredient",
    initialState: {
        ingredients: [],
        isLoading: false,
        hasError: false
    },
    reducers: {
        addIngredients: (state, action) => {
            state.ingredients = action.payload;
            state.isLoading = false;
            state.hasError = false;
        },
        toggleError: (state) => {
            state.isLoading = false;
            state.hasError = true;
        },
        toggleIsLoading: (state) => {
            state.isLoading = true;
            state.hasError = false;
        }
    }
})

export const { addIngredients, toggleError, toggleIsLoading } = ingredientReducer.actions;
export default ingredientReducer.reducer;