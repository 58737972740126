import API from "./client";

class CartAPI {
    async addToCart(data) {
        try {   
            const result = await API.post("cart/new/", data);
            return result.data
        } catch(e) {    
            console.log("Error in the addToCart api method");
            console.log(e.message);
        }
    };

    async removeCartItem(id) {
        try {
            const result = await API.delete(`cart/delete/${id}/`);
            return result.data;
        } catch(e) {
            console.log("Error in the removeCartItem api method");
            console.log(e.message);
        }
    };

    async cartCheckout(data) {
        try {
            const result = await API.post(`purchase/new/`, data);
            return result.data
        } catch(e) {
            console.log("Error in the cartCheckout api method");
            console.log(e.message);
        }
    };
    
}

export default CartAPI;