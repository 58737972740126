import { useState, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import "./Card.css";

function Card({ id, name, publisher, img }) {

    return (
        <div className="card-container">
            <section className="card-picture-holder" >
                <div 
                className="img-holder"
                style={{
                    backgroundImage: `url(${img})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  
                }}
                >
                    
                </div>
            </section>
            <section
            className="card-text-holder"
            >
                <Box
                className="card-box"
                >

                    <Typography 
                    variant="h4"
                    id="recipe-name"
                    >
                        {name}
                    </Typography>
                    <Typography
                    variant="h3"
                    id="publisher-name"
                    >
                        by {publisher}
                    </Typography>
                </Box>
                <Box
                className="card-box"
                >
                    <Button
                    id="box-btn"
                    variant="contained"
                    >
                        <NavLink
                        to={`recipes/${name}`}
                        id="watch-navlink"
                        >
                            Watch
                        </NavLink>
                    </Button>
                </Box>
            </section>
        </div>
    )
};

export default Card;