import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./user";
import recipeReducer from "./recipe";
import ingredientReducer from "./ingredient";

const store = configureStore({
    reducer: combineReducers({
        user: userReducer,
        recipe: recipeReducer,
        ingredient: ingredientReducer
    })
});

export default store;