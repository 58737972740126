import "./recipeComment.css";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
//
import RecipesAPI from "../../../api/recipe";
import { fetchRecipe } from "../../../store/recipe";
import { user0 } from "../../../utils/img";
import { delete0 } from "../../../utils/img";

export default function RecipeComment({ data }) {
    const dispatch = useDispatch();
    const { isAuthenticated, user } = useSelector(state => state.user);

    const handleDeleteComment = async() => {
        try {
            const result = await new RecipesAPI().deleteComment(data["id"]);
            if (result.name) {
                console.log(`Retrieved recipe from db is ${result.name}`);
                result["image"] = result["image"] ? `data:image/webp;base64,${result["image"]}` : null;
                return dispatch(fetchRecipe(result));
            }
            return console.log("Operation was not successful");
        } catch(e) {
            console.log(`Error in the handleDeleteComment function ${e.message}`);
        }
    }

    return (
        <div id="comment-box">
            <section id="author-box">
                <img
                id="author-img"
                src={user0}
                />
                <div>
                    <p
                    id="author-name"
                    >{data["publisher"]}</p>
                </div>
            </section>
            <section id="comment-content-box">
                <div className="comment-box">
                    <p id="content">
                        {data["content"]}
                    </p>
                </div>
                {/* ISSUE DELETION FROM HERE */}
                <div className="comment-box">
                    {data["publisher"] === user["username"] && (
                        <Button
                        id="delete-img"
                        onClick={handleDeleteComment}
                        >
                            <img 
                            src={delete0}
                            id="img-icon"
                            />
                        </Button>
                    )}
                </div>
            </section>  
        </div>
    )
}