import "./loadingScreen.css";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";


export default function LoadingScreen({}) {
    return (
        <div id="blur-box">
            <CircularProgress 
            id="loading-bar"
            size="5vw"
            thickness={6}
            />
        </div>
    )
}