import "./Home.css";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchRecipes, toggleHasError } from "../../store/recipe";
import Recipes from "../../api/recipe";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, CircularProgress } from "@mui/material";
import { FreeMode, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
// Custom components
import Card from "./card/Card";
import { chef } from "../../utils/img";
import LoadingScreen from "../shared/loadingScreen/loadingScreen";

function Home() {
    const dispatch = useDispatch();
    const API = new Recipes();
    const fillArr = new Array(10).fill(0);
    const { recipes } = useSelector(state => state.recipe);

    useEffect(() => {
        document.title = `Home | Django Delight`;
    }, []);

    useEffect(() => {
        if (recipes.length > 0) {

            console.log(`Recipes in the Home component are ${recipes[0].name}`);
        }
    }, [recipes]);

    return (
        <div className="home-overlay">
            <section className="recipes-header-container">
                <h2
                className="header-title"
                >
                    Get a nice tasty dish for yourself
                </h2>
            </section>
            <section className="recipes-container">
                <div className="recipes-inner-container">
                    {recipes.length > 0 ? (
                        
                        <Swiper
                    
                        id="freemode-swiper"
                        slidesPerView={2}
                        spaceBetween={5}
                        freeMode={true}
                        loop={true}
                        speed={2000}
                        autoplay={{pauseOnMouseEnter: true, delay: 1,  disableOnInteraction: true}}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode, Pagination, Autoplay]}
                        breakpoints={{
                            1250: {
                                slidesPerView: 3,
                                spaceBetween: 5,
                            },
                            450: {
                                slidesPerView: 2,
                                spaceBetween: 40
                            }
                        }}
                        >
                            {recipes.map((e, index) => (
                                <SwiperSlide key={index} id={`swiper-slide`}>
                                    <Card
                                    id={e["id"]} 
                                    name={e["name"]}
                                    publisher={e["publisher"]}
                                    img={e["image"]}
                                    />
                                </SwiperSlide>
                            ))}
                            
                        </Swiper>
                    ) : (
                        <LoadingScreen />
                    )} 
                </div>
            </section>
        </div>
    )
};

export default Home;