import useState, { useEffect } from 'react';
import { 
  BrowserRouter, 
  createRoutesFromElements,
  Route, 
  RouterProvider ,
  createBrowserRouter
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginUser } from './store/user';
// Import custom components
import Cart from './components/cart/Cart';
import Header from './components/header/Header';
import Home from './components/home/Home';
import Footer from './components/footer/Footer';
import Login from './components/Login/Login';
import Register from './components/register/Register';
import Profile from './components/profile/Profile';
import Recipes from './components/recipes/Recipes'; 
import Ingredients from './components/ingredients/Ingredients';
import RecipeDetails from './components/recipeDetails/RecipeDetails';
// Fonts
import './utils/fonts/Courgette.css';
import './utils/fonts/Angkor.css';
import './utils/fonts/Madimi.css';
// API
import { verifyLogin } from './api/user';
import { fetchRecipes, toggleHasError, toggleIsLoading } from './store/recipe';
import IngredientsAPI from './api/ingredient';
import RecipesAPI from './api/recipe';
//
import { toggleError, addIngredients } from './store/ingredient';

const IngredientsApi = new IngredientsAPI();
const RecipesApi = new RecipesAPI();

function App() {

  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);

  useEffect(() => {
    const getIngredients = async() => {
        try {
            const result = await IngredientsApi.getAll();
            if (result === "Ingredients could not be retrieved") {
                return dispatch(toggleError());
            }
            return dispatch(addIngredients(result));
        } catch(e) {
            console.log(e.message);
            console.log("Error in the useEffect in  the App.js file");
        }
    };
    getIngredients();
  }, []);

  useEffect(() => {
    const handleFetchRecipes = async() => {
        try {
            dispatch(toggleIsLoading());
            const result = await RecipesApi.getRecipes();
            for (let recipe of result) {
                if (recipe["image"]) {
                    recipe["image"] = `data:image/webp;base64,${recipe["image"]}`;
                } else {
                    continue
                }
            }
            if (result.length > 0) {
                return dispatch(fetchRecipes(result));
            } else {
                return dispatch(toggleHasError());
            }
        } catch(e) {
            console.log("Error in the handleFetchRecipes function in App.js");
            console.log(e.message);
        }
    }
    handleFetchRecipes()
  }, []);

  useEffect(() => {
    const checkIfLoggedIn = async() => {
      try {
        const result = await verifyLogin();
        if (result["username"]) {
          for (let cartItem of result["cart"]) {
            cartItem["image"] = `data:image/webp;base64,${cartItem["image"]}`;
          }
          return dispatch(loginUser(result));
        }
        return console.log("User is not logged in");

      } catch(e) {
        console.log("Error in the checkIfLoggedIn function, in the App.js file");
        console.log(e);
        return
      }
    }
    checkIfLoggedIn();
  }, []);


  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={<Header/>} >
    <Route path="/" element={<Footer />}>
      <Route path="" element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register /> } />
      <Route path="profile" element={<Profile />}></Route>
      <Route path="recipes" element={<Recipes />}></Route>
      <Route path="ingredients" element={<Ingredients />}></Route>
      <Route path="recipes/:name" element={<RecipeDetails />}></Route>
      <Route path="cart" element={<Cart />}></Route>
    </Route>
    </Route>
  ));

  return (
    <RouterProvider router={router}/>
  )
}

export default App;
