import { Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import "./Ingredients.css";
//
import Ingredient from "./ingredient/Ingredient";
import { addIngredients, toggleError } from "../../store/ingredient";
import { useSelector, useDispatch } from "react-redux";
import IngredientsAPI from "../../api/ingredient";

const fillArr = new Array(20).fill(0)
const API = new IngredientsAPI();

function Ingredients() {

    const { ingredients } = useSelector(state => state.ingredient);

    useEffect(() => {
        document.title = `Ingredients | Django Delight`;
    }, []);

    return (
        <div className="ingredients-overlay">
            <Typography
            id="stock-header" 
            variant="h4" 
            style={{
                fontFamily: "Courgette"
            }}>
                Available Stock
            </Typography>
            <section className="ingredients-inner-container">
                {ingredients.map((e, idx) => <Ingredient
                                         key={idx}
                                         name={e["name"]} 
                                         qty={e["qty"]}
                                         description={e["description"]}
                                         />)}
            </section>
        </div>
    )
};

export default Ingredients;