import { useState, useEffect } from "react";
import { loginUser, removeCartItemFromStore } from "../../../store/user";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Typography, Button, Modal, MenuItem, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import "./CartItem.css";
// Api
import CartAPI from "../../../api/cart";
import { verifyLogin } from "../../../api/user";
//
import { fetchUser } from "../../../api/user";

function CartItem({ id, name, img, quantity, price, ingredients }) {
    const CartApi = new CartAPI();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [qty, setQty] = useState(parseInt(quantity));
    const numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const ingredientsArr = useSelector(state => state.ingredient.ingredients);
    const { isAuthenticated, isLoading, invalidCredsUsed, user } = useSelector(store => store.user);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isAuthenticated === false) {
                navigate("/login");
            } 
        }, 100);
        return () => clearTimeout(timeoutId);
    }, [isAuthenticated]);

    // useEffect(() => {
    //     console.log(`Quantity of ${name} is ${quantity}`);
    // }, [quantity]);
    
    // useEffect(() => {
    //     console.log(`Quantity for item ${name} has changed to ${qty}`);
    // }, [qty]);

    useEffect(() => {
        console.log(`User cart has changed to ${user.cart[0].qty}`);
    }, [user.cart])

    const handleQtyChange = async(e) => {
        try {
            // return console.log("handleQtyChange runs");
            e.preventDefault();
            const result = await CartApi.addToCart({
                "name": name,
                "qty": e.target.value
            });
            if (result === "Cart Updated!") {
                const user_obj = await verifyLogin();
                if (user_obj["username"]) {
                    for (let cartItem of user_obj["cart"]) {
                        cartItem["image"] = `data:image/webp;base64,${cartItem["image"]}`;
                    }
                    dispatch(loginUser(user_obj));
                    return setQty(e.target.value);
                }
            }
            return console.log("Update was not successful");
        } catch(e) {
            console.log(e.message),
            console.log("Error i nthe handleQtyChange function");
        }
    };

    const handleDeleteItem = async(e) => {
        try {
            e.preventDefault();
            const result = await CartApi.removeCartItem(id);
            if (result === "Deletion successful!") {
                const user_obj = await verifyLogin();
                if (user_obj["username"]) {
                    for (let cartItem of user_obj["cart"]) {
                        cartItem["image"] = `data:image/webp;base64,${cartItem["image"]}`;
                    }
                    return dispatch(loginUser(user_obj));
                }
                return alert("Could not fetch user object");
            };
            return console.log("Deletion error, check server outputs");

        } catch(e) {
            console.log(e.message);
            console.log("Error in the handleDeleteItem function");
        }
    }

    return (
        <section className="cartitem-overlay">
            <div className="cartitem-box first">
                    <img
                    className="cartitem-img"
                    src={img}
                   />
                
            </div>
            <div className="cartitem-box second">
                <div className="name-container">
                    <Link
                    to={`/recipes/${name}`}
                    >
                        <Typography
                        className="name"
                        >
                            {name}
                        </Typography>
                    </Link>
                </div>
                <div className="edit-container">
                    <RemoveCircleOutlineIcon 
                    id="remove-icon"
                    onClick={handleDeleteItem}
                    />
                    <div className="qty-overview">
                        <TextField
                        required
                        select
                        id="quantity-select"
                        value={qty}
                        onChange={handleQtyChange}
                        >
                            {numArr.map((num, idx) => (
                                <MenuItem
                                key={idx}
                                value={num}
                                >
                                    {num}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>
            </div>
            <div className="cartitem-box three">
                <span className="one">{quantity}</span>
                <span className="two">for</span>
                <span className="three">{Math.floor(quantity * price * 10) / 10}</span>
            </div>
        </section>
    )

}

export default CartItem