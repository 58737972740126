import { createSlice } from "@reduxjs/toolkit";

const recipeSlice = createSlice({
    name: "recipe",
    initialState: {
        recipe: { ingredients: [], comments: [] },
        recipes: [],
        isLoading: false,
        hasError: false
    },
    reducers: {
        fetchRecipe: (state, action) => {
            Object.assign(state, { recipe: action.payload });
            state.isLoading = false;
            state.hasError = false;
        },
        fetchRecipes: (state, action) => {
            state.recipes = action.payload;
            state.isLoading = false;
            state.hasError = false;
        },
        toggleIsLoading: (state) => {
            state.hasError = false;
            state.isLoading = true;
        },
        toggleHasError: (state) => {
            state.isLoading = false;
            state.hasError = true;
        }
    }
});

export const { fetchRecipes, fetchRecipe, toggleHasError, toggleIsLoading } = recipeSlice.actions;
export default recipeSlice.reducer;