import "./Header.css";
import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { img0, img1, logo1, cart1 } from "../../utils/img";
import 
{ 
    AppBar,
    Button, 
    Badge,
    Box,
    Typography, 
    Divider, 
    List,
    ListItem, 
    ListItemButton, 
    ListItemText,
    Drawer
} from "@mui/material";
import { useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
//
import SearchBar from "./searchBar/SearchBar";

function Header() {
    const [open, setOpen] = useState(false);
    const [activeLink, setActiveLink] = useState("");
    const { isAuthenticated, user } = useSelector(store => store.user);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Box 
            id="header-outer-container"
            sx={{
                boxShadow: "none",
            }}
            >
                <header
                className="header-container"
                >

                    <div className="box-container first">
                        <NavLink 
                        className="return-navlink"
                        to="/"
                        >
                            
                            <img 
                            id="site-logo"
                            src={logo1}
                            />
                            <div>

                                <h3 className="site-title">Django's CraftFood</h3>
                            </div>
                        </NavLink>
                    </div>
                    <div className="box-container second">
                        <div 
                        className={`nav-holder ${activeLink === "Recipes" ? "active" : ""}`}
                        onClick={() => setActiveLink("Recipes")}
                        >
                            <NavLink to="/recipes" className="navlink-text">Recipes</NavLink>
                        </div>
                        <div 
                        className={`nav-holder ${activeLink === "Ingredients" ? "active" : ""}`}
                        onClick={() => setActiveLink("Ingredients")}
                        >
                            <NavLink to="/ingredients" className="navlink-text">Ingredients</NavLink>
                        </div>
                        {!isAuthenticated ? (
                            <>
                                <div 
                                className={`nav-holder ${activeLink === "Login" ? "active" : ""}`}
                                onClick={() => setActiveLink("Login")}
                                >
                                    <NavLink to="/login" className="navlink-text">Login</NavLink>
                                </div>
                                <div 
                                className={`nav-holder ${activeLink === "Register" ? "active" : ""}`}
                                onClick={() => setActiveLink("Register")}
                                >
                                    <NavLink to="/register" className="navlink-text">Register</NavLink>
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                className={`nav-holder ${activeLink === "Profile" ? "active" : ""}`}
                                onClick={() => setActiveLink("Profile")}
                                >
                                    <NavLink to="/profile" className="navlink-text">Profile</NavLink>
                                </div>
                                <div 
                                className={`nav-holder ${activeLink === "Cart" ? "active" : ""}`}
                                onClick={() => setActiveLink("Cart")}
                                >
                                    <NavLink 
                                    to="/cart"
                                    className="navlink-text"
                                    >
                                        <Badge badgeContent={user.cart.length} color="success">
                                            <img 
                                            src={cart1}
                                            className="cart-icon"
                                            />
                                        </Badge>
                                    </NavLink>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="box-container third">
                        <div className="two">
                            <SearchBar />
                        </div>
                        <div className="one">
                        {isAuthenticated && (
                            
                            <>
                                <span>Credits</span>
                                <Typography
                                variant="h4"
                                style={{
                                    fontFamily: "Madimi",
                                    margin: "1vw",
                                }}
                                >
                                    {user.credits}
                                </Typography>
                            </>
                        )}
                        </div>
                    </div>

                </header>
                <AppBar
                className="mobile-header-container"
                position="static"
                sx={{
                    backgroundColor: "floralwhite",
                }}
                >
                    <Box className="mobile-container first">
                        <NavLink 
                        className="return-navlink"
                        to="/"
                        >
                            
                            <img 
                            id="site-logo"
                            src={logo1}
                            />
                            <div>

                                <Typography className="site-title">Django's CraftFood </Typography>
                            </div>
                        </NavLink>
                    </Box>
                    <Box className="mobile-container second">
                        <SearchBar />
                        <MenuIcon
                        id="hamburger-icon"
                        onClick={handleOpen}
                        />
                        <Drawer
                        id="mobile-drawer"
                        anchor="right"
                        open={open}
                        onClose={handleClose}
                        sx={{
                            borderRadius: "20px",
                            
                        }}
                       
                        >
                            <Box
                            role="presentation"
                            id="navlink-container"
                            sx={{
                                backgroundColor: "floralwhite",
                                height: "100%"
                            }}
                            onClick={handleClose}

                            >
                                <List
                                id="navbar"
                                >
                                    {["Recipes", "Ingredients", !isAuthenticated ? "Login" : "Profile", !isAuthenticated ? "Register" : "Cart"]
                                    .map((navitem, idx) => (
                                        <Box 
                                        className={`listitem-box ${activeLink === navitem ? "active" : ""}`}
                                        >
                                            <ListItem
                                            id="navitem"
                                            
                                            >
                                                <ListItemButton>
                                                    <NavLink
                                                    className={({isActive}) => isActive ? "navlink active" : "navlink"}
                                                    to={navitem.toLowerCase()}
                                                    
                                                    >   
                                                        {navitem === "Cart" ? (
                                                            <Badge 
                                                            badgeContent={user.cart.length}
                                                            color="success"

                                                            >
                                                                <img 
                                                                src={cart1}
                                                                id="cart-icon"
                                                               
                                                                />
                                                            </Badge>
                                                        ) : (
                                                            <Typography                                           
                                                            id="navitem-text"
                                                            
                                                            >
                                                                {navitem}
                                                            </Typography>

                                                        )}
                                                    
                                                    </NavLink>

                                                </ListItemButton>
                                            </ListItem>
                                        </Box>
                                    ))}
                                </List>

                            </Box>
                        </Drawer>
                    </Box>
                    
                </AppBar>
            </Box>
            <Outlet />
        </>
    )
};

export default Header