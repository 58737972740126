import "./Register.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
// API
import { registerUser } from "../../api/user";

function Register() {

    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [verifiedPass, setVerifiedPass] = useState("");

    const [usernameInUse, setUsernameInUse] = useState(false);
    const [emailInUse, setEmailInUse] = useState(false);
    const [registerSuccessful, setRegisterSuccessful] = useState(false);
    const { isAuthenticated } = useSelector(state => state.user);

    useEffect(() => {
        if (isAuthenticated === true) {
            navigate("/");
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (registerSuccessful) {
            // console.log("Register successful");
            navigate("/login");
        }
    }, [registerSuccessful]);

    useEffect(() => {
        document.title = `Register | Django Delight`;
    }, []);

    const submitForm = async(e) => {
        try {
            e.preventDefault();
            // setUsername(false);
            // setEmailInUse(false);
            if (username.trim("").length < 1 || password.trim("").length < 1 || email.trim("").length < 1 || verifiedPass.trim("").length < 1) {
                return alert("Fill out every fields!");
            }
            if (password !== verifiedPass) {
                return alert("Passwords must be the same!");
            }
            const result = await registerUser({
                username,
                password,
                email
            });
            // console.log(`result from the registerUser function is ${result}`);
            if (result === "Username already in use") {
                return setUsernameInUse(true);
                
            } 
            if (result === "Email already in Use") {
                return setEmailInUse(true);
                
            }
            if (result.includes("created")) {
                return setRegisterSuccessful(true);
            }

        } catch(e) {
            console.log("Error in the submitForm function within the Register.js file");
            console.log(e);
            return
        }
    }

    return (
        <div className="register-overlay">
            <section className="login-inner-container">
                <div className="content-holder one">
                    <div className="content-inner-holder">
                        <h3 className="welcome-text">Howdy m8!</h3>
                    </div>
                </div>
                <div className="content-holder two">
                    <div className="login-cont-holder first">
                        <h1 className="login-title">Register</h1>
                    </div>
                    <div className="login-cont-holder second">
                        <form 
                        className="login-form"
                        onSubmit={submitForm}
                        >
                            <div className="field">
                                <label >Username</label>
                                <input 
                                type="text"
                                name="username" 
                                placeholder="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                />
                            </div>
                            <div className="field">
                                <label >E-mail</label>
                                <input 
                                type="email"
                                name="email" 
                                placeholder="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                />
                            </div>
                            <div className="field">
                                <label >Password</label>
                                <input 
                                type="password" 
                                name="password" 
                                placeholder="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                />
                            </div>
                            <div className="field">
                                <label >Verify Password</label>
                                <input 
                                type="password" 
                                name="password-retype" 
                                placeholder="verify password"
                                value={verifiedPass}
                                onChange={(e) => setVerifiedPass(e.target.value)}
                                required
                                />
                            </div>
                            <Button
                            id="submit-button" 
                            type="submit" 
                            variant="contained"
                            >
                                Register
                            </Button>
                            {usernameInUse && <p style={{color: "red", marginTop: "0.3vw"}}>Username in use</p>}
                            {emailInUse && <p style={{color: "red", marginTop: "0.3vw"}}>Email in use</p>}
                        </form>
                    </div>
                
                </div>
            </section>
        </div>
    )
};

export default Register