import API from "./client";

export const fetchUser = async(data) => {
    try {
        const result = await API.post("user/login/", data);
        return result.data
    } catch(e) {
        console.log("Error in the fetchUser function");
        console.log(e);
    }
};

export const verifyLogin = async() => {
    try {
        const result = await API.get("user/verify_login/");
        return result.data;
    } catch(e) {
        console.log("Error in the verifyLogin function");
        console.log(e);
    }
};

export const registerUser = async(data) => {
    try {
        const result = await API.post("user/new/", data);
        return result.data;
    } catch(e) {
        console.log("Error in registerUser api function");
        console.log(e);
    }
};

export const initiateLogout = async() => {
    try {
        const result = await API.get("user/logout/");
        return result.data;
    } catch(e) {
        console.log("Error in the initiateLogout function");
        console.log(e)
    }
};

export const modifyPassword = async(data) => {
    try {
        const result = await API.put("user/change_password/", data);
        return result.data;
    } catch(e) {
        console.log("Error in the modifyPassword api call");
        console.log(e)
    }
};

export const modifyUsername = async(data) => {
    try {
        const result = await API.put("user/change_username/", data);
        return result.data;
    } catch(e) {
        console.log("Error in the modifyUsername api call");
        console.log(e);
    }
};

export const modifyEmail = async(data) => {
    try {
        const result = await API.put("user/change_email/", data);
        return result.data;
    } catch(e) {
        console.log("Error in the modifyEmail api call");
        console.log(e);
    }
}